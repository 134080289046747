//ShopOnlineSec.jsx
import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../../styles/Home/ShopOnlineSec.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BlurTop from '../../images/backgroundImg/blur-top.svg';

const ShopOnlineSec = () => {
  const BackendURL = process.env.REACT_APP_BACKEND_URL;
  const imageServerURL = process.env.REACT_APP_IMAGE_SERVER_URL;
  const [products, setProducts] = useState([]);

  useEffect(() => {
    return () => {
      setProducts([]);
    };
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      //const url = 'nodejs.phoenixsolution.us/api/products'; // Update with your API URL

      const url = `${BackendURL}/api/products`; // API URL

      try {
        const response = await fetch(url);
        const result = await response.json();
        //console.log("-----------------------------", result); // Debug: Print fetched JSON data
        setProducts(result);
      } catch (error) {
        //console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <section className="shop-online-sec cmn-gap">
      <i className="blur-top">
        <img
          width="310"
          height="488"
          src={BlurTop}
          alt="blue vector"
          className="entered lazyloaded"
        />
        <noscript>
          "<img width="310" height="488" src={BlurTop} alt="blue vector"></img>"
        </noscript>
      </i>
      <div className="container">
        <div className="center-cont">
          <h2>Shop Wafers Online</h2>
          <p>
            Browse our inventory of high-quality silicon wafers or glass wafers
            at Silicon Phoenixsolution. Purchase directly from our website and explore our
            ready-to-ship options, designed to meet the specific requirements of
            projects ranging from research labs to high-volume production
            facilities.
          </p>
        </div>

        <div className="cmn-slider-wrap">
          {/* <Slider {...settings}> */}
          <div className="cmn-slider-wrap">
            {products.length > 0 && (
              <Swiper
                spaceBetween={10}
                slidesPerView={4} // Default view for screens wider than 1200px
                navigation={true}
                autoplay={{ delay: 3000 }}
                modules={[Navigation, Autoplay]}
                loop={true}
                breakpoints={{
                  1200: {
                    slidesPerView: 3, // 4 slides for screens 1200px and wider
                  },
                  1147: {
                    slidesPerView: 2, // 3 slides for screens 991px to 1199px
                  },
                  885: {
                    slidesPerView: 2, // 2 slides for screens 885px to 990px
                  },
                  454: {
                    slidesPerView: 1, // 1 slide for screens 454px to 884px
                  },
                  0: {
                    slidesPerView: 1, // 1 slide for screens smaller than 454px
                  },
                }}


              >
                {products.map((product) => (
                  <SwiperSlide key={product.id}>
                    <div className="cmn-card shop-cards">
                      <div className="cmn-card-pic">
                        <img src={`${imageServerURL}${product.image}`} alt={product.name} />
                      </div>
                      <a href="*" tabIndex="-1">
                        {product.name}
                      </a>
                      <div className="shop-price">
                        <span className="woocommerce-Price-amount amount">
                          <bdi>
                            <span className="woocommerce-Price-currencySymbol">$</span>{product.min_prize}
                          </bdi>
                        </span>
                        &nbsp; – &nbsp;
                        <span className="woocommerce-Price-amount amount">
                          <bdi>
                            <span className="woocommerce-Price-currencySymbol">$</span>{product.max_prize}
                          </bdi>
                        </span>
                      </div>
                      {/* <h3>{product.name}</h3>
                      <p>{product.description}</p>
                      <a href="#" className="lean-more">
                        Learn more <i className="fa-solid fa-arrow-up"></i>
                      </a> */}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>


          {/* </Slider> */}
        </div>
        <div className="btn-center" onClick={() => window.scrollTo(0, 0)} >
          <Link to="/store" onClick={() => window.scrollTo(0, 0)} >
            <button>Shop Now</button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ShopOnlineSec;