//MapComponent.jsx

// src/components/MapComponent.jsx
import React from 'react';
import '../../styles/AboutUs/MapComponent.scss';

const MapComponent = () => {
    return (
        <>
        <div className="MapComponent">
            <div className="Map">
                {/* <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.045729912642!2d72.78704723947632!3d21.19034226094101!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04dcc08354bef%3A0xafef3e45540c162c!2sNanotech%20Consultancy!5e0!3m2!1sen!2sin!4v1725387892475!5m2!1sen!2sin" 
                    allowFullScreen="" 
                    loading="lazy" 
                    referrerPolicy="no-referrer-when-downgrade"
                >
                </iframe> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6188.7219991576585!2d-77.22146448366478!3d39.1437701071419!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b62d1790d8b3d7%3A0x3ca6c4b74ed3a0ee!2sGaithersburg%2C%20MD%2020899%2C%20USA!5e0!3m2!1sen!2sin!4v1737644901452!5m2!1sen!2sin" 
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
            {/* <div className="contact-us">
                <h2>Get in touch</h2>
                <p>Silicon Phoenixsolution’s staff is comprised of well-trained professionals dedicated to providing you complete customer satisfaction.</p>
                <button>Contact Us</button>
            </div> */}
        </div>
        </>
    );
};

export default MapComponent;
