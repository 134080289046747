// ContactSection.jsx

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faClock, faPhone, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import '../../styles/ContactUsForm/ContactSection.scss';

const ContactSection = () => {
  const [activeSection, setActiveSection] = useState(null);

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  return (
    <div className="contact-section-container">
      <h2 className="section-title">Get In Touch</h2>
      <div className="accordion">
        {['headquarters', 'sales', 'support', 'marketing', 'inquiries'].map((section) => (
          <div key={section} className="accordion-item">
            <div className="accordion-header" onClick={() => toggleSection(section)}>
              <div className="icon"></div>
              <h3>{section.charAt(0).toUpperCase() + section.slice(1).replace(/-/g, ' ')}</h3>
              <FontAwesomeIcon
                icon={faChevronDown}
                className={`dropdown-icon ${activeSection === section ? 'rotate' : ''}`}
              />
            </div>
            {activeSection === section && (
              <div className="accordion-content">
                {section === 'headquarters' && (
                  <>
                    <p>
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                      Bethesda,Maryland Washington D.C., US 20899
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faPhone} className="icon" />
                      +91 8780490582
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faClock} className="icon" />
                      Monday – Friday 9:00 a.m. to 6:00 p.m.
                    </p>
                  </>
                )}
                {section === 'sales' && (
                  <>
                    <p>
                      <FontAwesomeIcon icon={faEnvelope} className="icon" />
                      sales@Silicon Phoenixsolution.com
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faPhone} className="icon" />
                      +91 8780490582
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faClock} className="icon" />
                      Monday – Friday, 9:00 a.m. to 6:00 p.m.
                    </p>
                  </>
                )}
                {section === 'support' && (
                  <>
                    <p>
                      <FontAwesomeIcon icon={faEnvelope} className="icon" />
                      cs@Silicon Phoenixsolution.com
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faPhone} className="icon" />
                      +91 8780490582
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faClock} className="icon" />
                      Monday – Friday, 9:00 a.m. to 6:00 p.m.
                    </p>
                  </>
                )}
                {section === 'marketing' && (
                  <>
                    <p>
                      <FontAwesomeIcon icon={faEnvelope} className="icon" />
                      marketing@Silicon Phoenixsolution.com
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faPhone} className="icon" />
                      +91 8780490582
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faClock} className="icon" />
                      Monday – Friday, 9:00 a.m. to 6:00 p.m.
                    </p>
                  </>
                )}
                {section === 'inquiries' && (
                  <>
                    <p>
                      <FontAwesomeIcon icon={faEnvelope} className="icon" />
                      info@Silicon Phoenixsolution.com
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faPhone} className="icon" />
                      +91 8780490582
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faClock} className="icon" />
                      Monday – Friday, 9:00 a.m. to 6:00 p.m.
                    </p>
                  </>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ContactSection;